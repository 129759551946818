import * as React from "react";
import * as styles from "./MailMagazineBanner.module.scss";
import {useEffect, useState} from "react";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-bootstrap";
import axios from "../../http/axios";
import {useLocation} from "react-router";
import {compact} from "lodash";

export const MAIL_MAGAZINE_STATE = 'mail_magazine_state';

const MailMagazineForm = ({onRegistrationComplete}) => {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isShop, setIsShop] = useState(undefined);
    const [ppChecked, setPPChecked] = useState(true);
    const onSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === true) {
            setDisabled(true);
            axios.post('/magazine/register.json', {isShop, name, email}).then(res => {
                setDisabled(false);
                onRegistrationComplete();
            }).catch(() => {
                setDisabled(false);
            });
        }

        return false;
    };
    return <Form noValidate validated={validated} className={compact([styles.form, validated ? styles.validated : undefined]).join(' ')} onSubmit={onSubmit}>
        <h2 className={styles.formTitle}>メールマガジンに登録</h2>
        <div>
            <Form.Check inline type="radio" label="一般" name="subscriber_type" value="normal" id="subscriber-type-0"
                        checked={isShop === false} onChange={e => setIsShop(!e.target.checked)} required/>
            <Form.Check inline type="radio" label="飲食店用" name="subscriber_type" value="shop" id="subscriber-type-1"
                        checked={isShop === true} onChange={e => setIsShop(e.target.checked)} required/>
        </div>
        <Form.Control placeholder="お名前" className="mb-2" value={name} onChange={e => setName(e.target.value)} required/>
        <Form.Control placeholder="Email" className="" type="email" value={email} onChange={e => setEmail(e.target.value)} required/>
        <div className={styles.ppCheck}>
            <input id="pp-check" checked={ppChecked} onChange={() => setPPChecked(!ppChecked)} type="checkbox" required />
            <label htmlFor="pp-check">
                <a href="/privacy-policy" target="_blank" className="me-2">プライバシー・ポリシー</a>
                に同意
            </label>
        </div>
        <button disabled={disabled}>登録</button>
    </Form>;
};
const Banner = ({onClick}) => <>
    メールマガジンに登録して
    <br/>
    最新情報をお見逃しなく！
    <button onClick={onClick}>登録</button>
</>;

const Thanks = ({onClose}) => <>
    メールマガジンに登録しました。
    <br/>
    配信は次号からとなります。
    <button onClick={onClose}>閉じる</button>
</>;

type BannerMode = "banner" | "form" | "thanks";
export const MailMagazineBanner = () => {
    const location = useLocation();
    const [open, setOpen] = useState(true);
    const [klasses, setKlasses] = useState([styles.bannerBg]);
    const [bannerMode, setBannerMode] = useState("banner" as BannerMode);
    const onClick = () => {
        setBannerMode("form");
    };
    const dismiss = () => {
        if (bannerMode === "thanks") {
            localStorage.setItem(MAIL_MAGAZINE_STATE, "registered");
        }
        setOpen(false);
    };
    const onRegistrationComplete = () => {
        setBannerMode("thanks");
    };
    useEffect(() => {
        if (location.pathname === '/privacy-policy' ||
            localStorage.getItem(MAIL_MAGAZINE_STATE) === 'registered' ||
            !open) {
            setKlasses([styles.bannerBg]);
        } else {
            setKlasses([styles.bannerBg, styles.open]);
        }
    }, [open]);

    return <div className={klasses.join(' ')}>
        <div className={styles.bannerFrame}>
            <button className={styles.close} onClick={dismiss}>
                <FontAwesomeIcon icon={faClose}/>
            </button>
            <div className={styles.banner}>
                {
                    bannerMode === "banner" && <Banner onClick={onClick}/>
                }
                {
                    bannerMode === "form" && <MailMagazineForm onRegistrationComplete={onRegistrationComplete}/>
                }
                {
                    bannerMode === "thanks" && <Thanks onClose={() => {
                        dismiss();
                    }}/>
                }
            </div>
        </div>
    </div>;
};